import React, { useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import dayjs from 'dayjs';
import StyledPostIt from './StyledPostIt';
import ColourSelector from './ColourSelector';
import useDoubleClick from './utils/useDoubleClickHook.js';
import HoverControls from './Controls/HoverControls';
import SelectedControls from './Controls/SelectedControls';
import EditableBody from './Controls/EditableBody';
import EditableTitle from './Controls/EditableTitle';
import Reactions from './Controls/Reactions';

const PostIt = ({
    note,
    myKey,
    handleStop,
    handleChange,
    activeNote,
    setActiveNote,
    handleResizeStop,
    handleChangeContent,
    hoveredNote,
    handleDelete,
    setHovered,
    user,
    newNote
  }) => {
  const [showColourSelector, setShowColourSelector] = useState(false);
  const [tempText, setTempText] = useState(note.content.content)
  const [tempTitle, setTempTitle] = useState(note.content.title)

  const enableEdit = () => {
    if (user.id !== note.author && user.role !== 'ADMIN') {
      return
    }
    setActiveNote(myKey);
  }

  const [postItRef, elem] = useDoubleClick(enableEdit);

  useEffect(() => {
    if(activeNote !== myKey && showColourSelector === true) {
      setShowColourSelector(false);
    }
  }, [activeNote]);

   useEffect(() => {
    if(note.content.content !== tempText) {
      setTempText(note.content.content);
    }
    if(note.content.title !== tempTitle){
      setTempTitle(note.content.title);
    }
   }, [note])


  const deleteNote = () => {
    if (user.id !== note.author && user.role !== 'ADMIN') {
      return
    }
    handleDelete(myKey)
  }

  const setColourSelectorDisplay = () => {
    if (user.id !== note.author && user.role !== 'ADMIN') {
      return
    }
    setShowColourSelector(true);
  }

  const changeColour = (newColour) => {
    if (user.id !== note.author && user.role !== 'ADMIN') {
      return
    }
    const holder = {
      target: {
        value: newColour
      }
    }
    handleChange(holder, myKey, 'colour')
  }

  const handleMouseEnter = () => {
    if (user.id !== note.author && user.role !== 'ADMIN') {
      return
    }
    setHovered(myKey);
  }

  const handleMouseLeave = () => {
    setHovered('');
  }

  const shouldDisable = () => {
    if (user.id !== note.author && user.role !== 'ADMIN') {
      return true
    } else {
      return activeNote === myKey
    }
  }

  const enableResize = () => {
    if (user.id !== note.author && user.role !== 'ADMIN') {
      return false
    } else {
      return true
    }
  }

  const saveAndDeselect = () => {
    saveChanges();
    setActiveNote('');
    setHovered('');
    return;
  }

  const saveChanges = () => {
    updateContentEditable();
    updateContentEditableTitle();
  }

  const updateContentEditable = () => {
    handleChangeContent(tempText, myKey, 'content');
  }

  const updateContentEditableTitle = () => {
    handleChangeContent(tempTitle, myKey, 'title');
  }

  const saveContentEditable = (text) => {
    setTempText(text);
  }

  const saveContentEditableTitle = (text) => {
    setTempTitle(text);
  }

  const loseFocusOnEditable = () => {
    updateContentEditable();
  }

  const loseFocusOnEditableTitle = () => {
    updateContentEditableTitle();
  }

  const enablePointer = () => user.id === note.author || user.role === 'ADMIN';

  return (
    <Rnd
      position={note.position}
      size={note.size}
      onDragStop={(e, data) => handleStop(data, myKey)}
      onResizeStop={(e, direction, ref, delta, position) => {
        handleResizeStop(e, myKey, direction, ref, delta, position)
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      minWidth={300}
      minHeight={100}
      style={{
        outline: activeNote === myKey ? '3px pink dashed' : '3px transparent dashed',
        outlineOffset: 5
      }}
      disableDragging={shouldDisable()}
      enableResizing={enableResize()}
      >
      <StyledPostIt
        myColour={note.colour}
        ref={postItRef}
      >
        {
          hoveredNote === myKey && activeNote !== myKey && (
            <HoverControls note={note} newNote={newNote} enableEdit={enableEdit} deleteNote={deleteNote} />
          )
        }
        <Reactions note={myKey} reactions={note.reactions} user={user.id} />
        {
          activeNote === myKey && (
            <SelectedControls
              note={note}
              openColourWindow={setColourSelectorDisplay}
              save={saveChanges}
              saveAndClose={saveAndDeselect}
            />
          )
        }
        {
          showColourSelector && (
            <ColourSelector changeColour={changeColour} />
          )
        }
        <EditableTitle
          tempText={tempTitle}
          save={saveContentEditableTitle}
          blur={loseFocusOnEditableTitle}
          enabled={activeNote === myKey}
        />
        <div className="body">
          <EditableBody
            tempText={tempText}
            save={saveContentEditable}
            blur={loseFocusOnEditable}
            enabled={activeNote === myKey}
            activeNote={activeNote}
            myKey={myKey}
          />
        </div>
        <div className="footer">Posted by {note.meta.user.name},  {dayjs(note.createdAt).format('D MMM, YYYY h:mma')}</div>
      </StyledPostIt>
    </Rnd>
  )
}

export default PostIt