import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_BOARDS_LOADING,
  GET_BOARDS_SUCCESS,
  GET_BOARDS_FAIL,
  EDIT_BOARD_LOADING,
  EDIT_BOARD_SUCCESS,
  EDIT_BOARD_FAIL,
  DELETE_BOARD_LOADING,
  DELETE_BOARD_SUCCESS,
  DELETE_BOARD_FAIL,
  CREATE_BOARD_LOADING,
  CREATE_BOARD_SUCCESS,
  CREATE_BOARD_FAIL
} from '../types';

export const getBoards = () => async (dispatch, getState) => {
  dispatch({
    type: GET_BOARDS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get('/api/boards', options);
    console.log(response);
    dispatch({
      type: GET_BOARDS_SUCCESS,
      payload: { boards: response.data.boards },
    });
  } catch (err) {
    dispatch({
      type: GET_BOARDS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const createBoard = (board) => async (dispatch, getState) => {
  dispatch({
    type: CREATE_BOARD_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.post('/api/boards/new', board, options);
    console.log(response);
    dispatch({
      type: CREATE_BOARD_SUCCESS,
      payload: { board: response.data.board },
    });
  } catch (err) {
    dispatch({
      type: CREATE_BOARD_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const updateBoard = (updates) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_BOARD_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.post('/api/boards', updates, options);
    console.log(response);
    dispatch({
      type: EDIT_BOARD_SUCCESS,
      payload: { board: response.data.board },
    });
  } catch (err) {
    dispatch({
      type: EDIT_BOARD_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const deleteBoard = (board) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_BOARD_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.post('/api/boards/delete', board, options);
    console.log(response);
    dispatch({
      type: DELETE_BOARD_SUCCESS,
      payload: { board: response.data.board },
    });
  } catch (err) {
    dispatch({
      type: DELETE_BOARD_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};