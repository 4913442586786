import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const SelectModal = ({
    user,
    onLogOut,
    boards,
    history,
    checkPage
  }) => {
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if(boards.length > 0) {
      setSelected(boards[0].id)
    }
  }, [boards]);

  const handleChangeSelect = e => {
    setSelected(e.target.value);
  }

  const handleSubmit = e => {
    e.preventDefault();
    history.push(`/${selected}`);
    checkPage(selected);
  }

  return (
    <>
      <div>
        <p><span className="emphasis">{user.name}</span> <span className="logoutlink" onClick={onLogOut}>(Not you?)</span></p>
      </div>
      <form onSubmit={handleSubmit}>
        {
          user.role === "ADMIN" && (
            <p>
              <Link style={{color: '#fff'}} to="/admin">Add/Edit available wallboards</Link>
            </p>
          )
        }
        <label htmlFor="area">Select Board</label>
        <select value={selected} onChange={handleChangeSelect} name="area">
          {
            boards && boards.length > 0 && (
                boards.map(board => <option key={board.id} value={board.id}>{board.title}</option>)
              )
          }
        </select>
        <button type="submit">Select</button>
      </form>
    </>
  )
};

export default SelectModal