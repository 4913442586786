import React from 'react';
import StyledControls from './StyledControls';
import { ReactComponent as SaveIcon } from './save.svg';
import { ReactComponent as FillIcon } from './fill.svg';
import { ReactComponent as CancelIcon } from './close.svg';

const SelectedControls = ({ note, openColourWindow, save, saveAndClose }) => {

  return (
    <StyledControls
      atts={note}
    >
      <div onClick={save} className="control">
        <SaveIcon />
      </div>
      <div onClick={openColourWindow} className="control">
        <FillIcon />
      </div>
      <div onClick={saveAndClose} className="control">
        <CancelIcon />
      </div>
    </StyledControls>
  )
};

export default SelectedControls;