import React from 'react';
import Modal from 'react-modal';
import StyledModal from '../../Modals/StyledModal';
import styled from 'styled-components';

const List = styled.li`
  display: flex;
  position: relative;

  .useravatar {
    width: 40px;
    height: 40px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 16px;
  }

  svg {
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 5px;
    left: 25px;
  }
`;

Modal.setAppElement('#root');

const ReactionList = ({ user, reaction, emojis }) => {
  if(!reaction) return null;
  const Icon = emojis[reaction].icon;
  return (
  <List>
    <img className="useravatar" src={user.avatar} />
    <Icon />
    <p>{user.name}</p>
  </List>
)};

const ReactionModal = ({
    open,
    toggle,
    reactions,
    emojis
  }) => {
  return (
      <StyledModal
        isOpen={open}
        onRequestClose={toggle}
      >
        <h1 style={{ textAlign: 'center' }}>Reactions</h1>
        <ul>
        {
          reactions.map(react => <ReactionList key={react._id} emojis={emojis} {...react} />)
        }
        </ul>
      </StyledModal>
  )
}

export default ReactionModal;