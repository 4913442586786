import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import requireAdmin from '../../hoc/requireAdmin';
import {
  getBoards,
  updateBoard,
  createBoard,
  deleteBoard
} from '../../store/actions/boardsActions';
import Loader from '../../components/Loader/Loader';
import styled from 'styled-components';

const StyledAdmin = styled.div`
  margin: 50px 0 0;
  padding: 0 20px;
  max-height: 80vh;
  overflow: scroll;

  .admincontent {
    width: 600px;
    margin: 0 auto;
  }

  .heading {
    display: flex;
    justify-content: space-between;
  }

  h1 {
    color: rgb(251, 191, 9);
    letter-spacing: -1px;
    margin: 0;
  }

  h6 {
    margin: 0;
    color: #415263;
    font-size: 0.9rem;
    line-height: 48px;

    i {
      font-size: 0.75em;
    }
  }

  h2 {
    color: #415263;
  }

  button {
    text-align: left;
  }

  .boardEdit {
    margin-bottom: 40px;
  }

  .add {
    margin-bottom: 81px;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      left: 0;
      right: 0;
      position: absolute;
      height: 2px;
      background-color: #415263;
      bottom: -46px;
    }
  }

  label {
    color: #415263;
  }

  input[type='text'] {
    background-color: #fff;
    color: #415263;
  }

  input[type='submit'] {
    opacity: 1;
    border: 1px solid #415263;
    color: #415263;
    background-color: transparent;
    border-radius: 0;
    cursor: pointer;

    &:hover {
      background-color: #415263;
      border-color: #415263;
      color: #fff;
    }
  }

`;

const Admin = ({
    updateBoard,
    getBoards,
    createBoard,
    deleteBoard,
    boards: {
      boards,
      isLoading,
      error
    }
  }) => {
  useEffect(() => {
    getBoards()
  }, []);

  const updateBoardHandler = (e) => {
    e.preventDefault();
    updateBoard({
      id: e.target.id.value,
      title: e.target.title.value
    });
  }

  const deleteBoardHandler = e => {
    e.preventDefault();
    if(window.confirm('Are you sure you want to delete this wallboard?')) {
      deleteBoard({
        id: e.target.id.value
      })
    }
  }

  const handleCreateSubmit = e => {
    e.preventDefault();
    createBoard({
      title: e.target.name.value
    });
  }

  return (
    <StyledAdmin className="admin-page">
      <div className="admincontent">

      <div className="heading">
        <h1>Admin dashboard</h1>
        <Link to="/">
          <h6><i className="fa fa-chevron-left" aria-hidden="true"></i> Back home</h6>
        </Link>
      </div>
      <div className="add">
        <h2>Add new wallboard</h2>
        <form onSubmit={handleCreateSubmit}>
          <label>Board Name:</label>
          <input type="text" name="name" placeholder="name" />
          <input type="submit" value="Add New Board +" />
        </form>
      </div>
      <div className="edit">
        <h2>Edit existing Wallboards</h2>
        { isLoading === true ? (
          <Loader />
        ) : (
          <>
            { error !== null && <p>{error}</p> }
            {
              boards.map(board => (
                <div key={board.id} className="boardEdit">
                  <form onSubmit={updateBoardHandler}>
                    <label>Change name</label>
                    <input type="text" name="title" defaultValue={board.title} />
                    <input type="hidden" name="id" defaultValue={board.id} />
                    <input type="submit" value="Update Board Name" />
                  </form>
                  <form onSubmit={deleteBoardHandler}>
                    <input type="hidden" name="id" defaultValue={board.id} />
                    <input type="submit" value="Delete Board" />
                  </form>
                </div>
              ))
            }
          </>
        )}
      </div>
      </div>
    </StyledAdmin>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  boards: state.boards
});

export default requireAdmin(compose(connect(mapStateToProps, {
  getBoards,
  updateBoard,
  createBoard,
  deleteBoard
}))(Admin))
