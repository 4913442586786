import { combineReducers } from 'redux';

import authReducer from './authReducer';
import registerReducer from './registerReducer';
import userReducer from './userReducer';
import usersReducer from './usersReducer';
import notesReducer from './notesReducer';
import boardsReducer from './boardsReducer';
import labelReducer from './labelReducer';

export default combineReducers({
  auth: authReducer,
  register: registerReducer,
  user: userReducer,
  users: usersReducer,
  boards: boardsReducer,
  notes: notesReducer,
  label: labelReducer
});
