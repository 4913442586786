import React from 'react';
import styled from 'styled-components';
import { colours } from './utils/colours';

const StyledSelector = styled.div`
  position: absolute;
  top: 0;
  right: -97px;
  width: 90px;
  background-color: #ddd;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 4px;
  box-sizing: border-box;

  .colour {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    cursor: pointer;
  }
`;

const ColourSelector = ({ changeColour }) => {

  const handleClick = (colour) => {
    console.log('que asco!')
    changeColour(colour);
  }

  return (
    <StyledSelector>
      {
        colours.map(colour => (
          <div
            className="colour"
            key={colour}
            style={{
              backgroundColor: colour
            }}
            onClick={() => handleClick(colour)}
          />
        ))
      }
    </StyledSelector>
  )
};

export default ColourSelector;