import React from 'react';
import { GOOGLE_AUTH_LINK } from '../../constants';
import { connect } from 'react-redux';

const LoginModal = ({ auth }) => (
  <>
    <form>
      <h2>Log in</h2>
      <a className="google btn" href={GOOGLE_AUTH_LINK}>
        <i className="fa fa-google fa-fw" />
        Login with Google
      </a>
      {auth.error && <p className="error">{auth.error}</p>}
    </form>
  </>
);

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(LoginModal);