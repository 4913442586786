import React from 'react';
import Modal from 'react-modal';
import { ReactComponent as Logo } from '../../logo.svg';
import SelectModal from './SelectModal';
import LoginModal from './LoginModal';
import StyledModal from './StyledModal';

Modal.setAppElement('#root');

const BoardModal = ({
    isOpen,
    closeModal,
    boards,
    user,
    logOut,
    type = 'select',
    history,
    checkPage
  }) => {
  const handleSubmit = e => {
    e.preventDefault();
  }

  const onLogOut = (event) => {
    event.preventDefault();
    logOut();
  };

  const selectBoard = () => {

  }

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div className="header">
        <h1><span className="emphasis">Success</span> Board</h1>
        <Logo />
      </div>
      {
        type === 'select' ? (
          <SelectModal user={user} onLogOut={onLogOut} selectBoard={selectBoard} boards={boards} history={history} checkPage={checkPage} />
        ) : (
          <LoginModal />
        )
      }

    </StyledModal>
  )
};

export default BoardModal;