import React from 'react';
import styled from 'styled-components';

const StyledReaction = styled.div`
  display: flex;
  margin-left: 5px;
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;
    margin-right: 2px;
    transform: scale(0.8);
    opacity: .5;
    transition: opacity 200ms cubic-bezier(0.420, 0.000, 0.520, 5.50), transform 200ms cubic-bezier(0.420, 0.000, 0.520, 5.50);
  }

  .count {
    line-height: 30px;
    height: 30px;
    color: #3D5161;
    font-weight: 600;
  }

  &.activeReaction {
    svg {
      opacity: 1;
      transform: scale(1);
    }
    .count {
      color: ${props => props.activeColour}
    }
  }
`;

const Reaction = ({
    icon,
    count,
    index,
    colour,
    className,
    clickHandler
  }) => {
  const Icon = icon;

  const setActive = () => {
    clickHandler(index);
  }

  return (
    <StyledReaction
      activeColour={colour}
      className={className}
      onClick={setActive}
    >
      <Icon />
      <div className="count">
        {
          count > 0 && count
        }
      </div>
    </StyledReaction>
  )
};

export default Reaction;