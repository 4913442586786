import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import StyledNav from './StyledNav'
import { logOutUser } from '../../store/actions/authActions';
import { ReactComponent as Logo } from '../../logo.svg';
import BoardNav from '../Board/BoardNav';

const Navbar = ({ auth, label, logOutUser, history }) => {
  const onLogOut = (event) => {
    event.preventDefault();
    logOutUser(history);
  };

  return auth.isAuthenticated ? (
    <StyledNav>
      <h2 className="logo"><BoardNav label={label} /> Success Board</h2>
      <ul className="nav-links flex-1">
        <li className="flex-1" />
        <img referrerPolicy="no-referrer" className="avatar" src={auth.me.avatar} />
        {/* <li className="nav-item" onClick={onLogOut}>
          <a href="#">Log out</a>
        </li> */}
        <Logo />
      </ul>
    </StyledNav>
  ) : (
    null
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  label: state.label
});

export default compose(withRouter, connect(mapStateToProps, { logOutUser }))(Navbar);
