import React from 'react';
import ContentEditable from 'react-contenteditable';

class EditableTitle extends React.Component {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef();
    this.state = {
      html: this.props.tempText
    };
  };

  handleChange = evt => {
    this.setState({html: evt.target.value});
    this.props.save(evt.target.value);
  };

  handleBlur = () => {
    this.props.blur();
  }

  render = () => {
    return (
      <div
        onBlur={this.handleBlur}
        className="title"
      >
        <ContentEditable
          innerRef={this.contentEditable}
          ref="editable"
          html={this.state.html}
          disabled={!this.props.enabled}
          onChange={this.handleChange}
        />
      </div>
    )
  };
};

export default EditableTitle