import React from 'react';
import ContentEditable from 'react-contenteditable';
import styled from 'styled-components';

const Richtext = styled.div`
  display: block;
  opacity: 1;
  position: absolute;
  top: 20px;
  /* bottom: 0; */
`;

const UrlWindow = styled.div`
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 150px;
  padding: 10px;

  p {
    margin-bottom: 0;
    color: #415263;
    font-weight: 800;
  }

  input {
    height: 22px;
    margin-bottom: 22px;
    margin-top: 16px;
    background-color: #e9e9ed;
    border-radius: 0;
    /* border: 1px solid #415263; */
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  button {
    background-color: #415263;
    color: #fff;
    padding: 5px 10px;
    border: none;
    /* border-radius: 20px; */
  }

  button.cancel {
    background-color: #fff;
    border: 2px solid #415263;
    color: #415263;
  }
`;

function EditButton(props) {
  return (
    <button
      key={props.cmd}
      onMouseDown={evt => {
        evt.preventDefault(); // Avoids loosing focus from the editable area
        document.execCommand(props.cmd, false, props.arg); // Send the command to the browser
      }}
    >
      {props.name || props.cmd}
    </button>
  );
}

class EditableBody extends React.Component {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef();
    this.addLink = this.addLink.bind(this);
    this.state = {
      html: this.props.tempText,
      focus: false,
      urlWindow: false
    };
  };

  handleChange = evt => {
    console.log(evt.target.value);
    this.setState({html: evt.target.value});
    this.props.save(evt.target.value);
  };

  handleFocus = () => {
    this.setState({
      ...this.state,
      focus: true
    });
  }

  handleBlur = () => {
    this.setState({
      ...this.state,
      focus: false
    });
    this.props.blur();
  }

  addLink = (e) => {
    e.preventDefault();
    const linkURL = prompt("Enter URL", "http://");
    const sText = document.getSelection();
    document.execCommand("insertHTML", false, '<a href="' + linkURL + '" target="_blank">' + sText + '</a>');

    // this.setState({html: this.contentEditable.current.innerHTML});
    // this.props.save(this.contentEditable.current.innerHTML);
  }

  render = () => {
    return (
      <div
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        style={{
          height: '100%'
        }}
      >
        {

          this.state.focus && this.props.activeNote === this.props.myKey && (
            <Richtext>
              <EditButton cmd="bold">Bold</EditButton>
              <EditButton cmd="italic">Italic</EditButton>
              <EditButton cmd="formatBlock" arg="h1" name="heading">Heading</EditButton>
              <button onMouseDown={this.addLink}>link</button>
            </Richtext>
          )
        }
        {/* {
        this.state.urlWindow && (
          <UrlWindow>
            <p>Enter URL Below</p>
            <input value="http://" />
            <div className="buttons">
              <button className='cancel'>Cancel</button>
              <button>Confirm</button>
            </div>
          </UrlWindow>
          )
        } */}
        <ContentEditable
          innerRef={this.contentEditable}
          ref="editable"
          html={this.state.html}
          disabled={!this.props.enabled}
          onChange={this.handleChange}
          style={{height: '100%'}}
        />
      </div>
    )
  };
};

export default EditableBody