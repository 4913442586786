import React from 'react';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from './Controls/add.svg';
import { ReactComponent as RefreshIcon } from './Controls/reload.svg';

const StyledTools = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
`;

const Tool = styled.div`
  border-radius: 100%;
  background-color: #FBBF0999;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  margin-left: 12px;

  &.refresh {
    background-color: #41526399;

    svg {
      fill: #fff;
    }
  }

  div {
    height: 100%;

    svg {
      max-width: 60%;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const Tools = ({ newNote, refresh }) => (
  <StyledTools>
    <Tool onClick={refresh} className="refresh">
      <div><RefreshIcon /></div>
    </Tool>
    <Tool onClick={newNote}>
      <div><AddIcon /></div>
    </Tool>
  </StyledTools>
);

export default Tools