import styled from 'styled-components';

const StyledNav = styled.nav`
  /* position: fixed; */
  background-color: #415263;
  padding-left: 30px;
  padding-right: 30px;
  height: 80px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;

  .logo {
    display: inline-block;
    color: white;
    margin: 0;
    white-space: nowrap;
    letter-spacing: -1px;
  }

  .nav-links {
    list-style: none;
    font-size: 20px;
    display: flex;
    margin: 5px 0;
  }

  .nav-item {
    display: inline-flex;
  }

  .nav-item a {
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;
    color: white;
    white-space: nowrap;
  }

  .nav-item:hover {
    background-color: white;
  }

  .nav-item:hover a {
    color: darkblue;
  }

  .flex-1 {
    flex: 1;
  }

  .avatar {
    width: 40px;
    height: 40px;
    align-self: center;
    margin-right: 25px;
    object-fit: cover;
    border-radius: 50%;
  }

  svg {
    height: 50px;
    transform: translateY(-2px);
  }
`;

export default StyledNav;