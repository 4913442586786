import {
  SET_LABEL
} from '../types';

const initialState = {
  label: '',
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_LABEL:
      return {
        ...state,
        label: payload.label,
      };
    default:
      return state;
  }
}
