import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_NOTES_LOADING,
  GET_NOTES_SUCCESS,
  GET_NOTES_FAIL,
  UPDATE_NOTE_SUCCESS
} from '../types';

export const getNotes = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_NOTES_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get('/api/notes/' + id, options);

    dispatch({
      type: GET_NOTES_SUCCESS,
      payload: { notes: response.data.notes },
    });
  } catch (err) {
    dispatch({
      type: GET_NOTES_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const updateNote = (note, shouldDelete) => async (dispatch, getState) => {
  const sendNote = {
    ...note,
    shouldDelete,
  }
  // dispatch({
  //   type: GET_NOTES_LOADING,
  // });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.post('/api/notes/', sendNote, options);

    dispatch({
      type: GET_NOTES_SUCCESS,
      payload: { notes: response.data.notes },
    });
  } catch (err) {
    dispatch({
      type: GET_NOTES_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
}

export const reactToNote = (note, reaction = null) => (dispatch, getState) => {
  return new Promise(async (res, rej) => {
  const sendPayload = {
    note,
    reaction
  }
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.post('/api/notes/react', sendPayload, options);

    dispatch({
      type: GET_NOTES_SUCCESS,
      payload: { notes: response.data.notes },
    });
    res(response.data.notes);
  } catch (err) {
    dispatch({
      type: GET_NOTES_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
    rej(err);
  }
  });
}
