import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Reaction from './Reaction';
import { ReactComponent as Like } from './like.svg';
import { ReactComponent as Love } from './love.svg';
import { ReactComponent as Wow } from './wow.svg';
import { ReactComponent as Yay } from './yay.svg';
import { ReactComponent as Haha } from './haha.svg';
import { reactToNote } from '../../../store/actions/notesActions';
import { connect } from 'react-redux';
import ReactionModal from './ReactionModal';

const emojis = {
  'like': {
    type: 'like',
    icon: Like,
    colour: '#1496EE',
  },
  'love': {
    type: 'love',
    icon: Love,
    colour: '#FA5865',
  },
  'wow': {
    type: 'wow',
    icon: Wow,
    colour: '#FBBF09',
  },
  'yay': {
    type: 'yay',
    icon: Yay,
    colour: '#FBBF09',
  },
  'haha':{
    type: 'haha',
    icon: Haha,
    colour: '#FBBF09',
}};

const emptyCount = {
  'like': 0,
  'love': 0,
  'wow': 0,
  'yay': 0,
  'haha': 0,
}

const StyledReactions = styled.div`
  position: absolute;
  bottom: -55px;
  height: 50px;
  right: 0;
  display: flex;

  button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 5px;
    cursor: pointer;
    outline: none;
    font-size: 29px;
    background-color: #CECECE;
    border: 1px solid #CECECE;
    color: #5B626A;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 1px rgba(0,0,0,0.1);
    transform: scale(0.8);

    &:hover {
      background-color: #939393;
      border-color: #939393;
      color: #fff;
    }

    span {
      display: inline-block;
      transform: translate(-4px,-11px);
    }
  }
`;

const Reactions = ({
    reactions,
    note,
    user,
    reactToNote
  }) => {
  const [activeReaction, setActiveReaction] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [counts, setCounts] = useState(emptyCount);

  const countReactions = async () => {
    const newCount = {...emptyCount};
    console.log(newCount);
    await reactions.map(react => {
      newCount[react.reaction]++;
    });
    await setCounts(newCount);
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  }

  const checkUserReact = async () => {
    if(reactions.length === 0) return;
    const active = await reactions.filter(react => {
      if(react.user._id === user) {
        return true;
      } else {
        return false;
      };
    });
    if(active.length > 0) {
      setActiveReaction(active[0].reaction);
    }
  }

  useEffect(() => {
    countReactions();
    checkUserReact();
  }, [reactions]);


  const clickReaction = (index) => {
    if(emojis[index].type === activeReaction) {
      reactToNote(note, '').then(() => {
        setActiveReaction('');
      })
    } else {
      const reaction = emojis[index].type;
      reactToNote(note, reaction);
    }
  }

  return (
    <StyledReactions>
      {
        Object.keys(emojis).map((i, key) => {
          const emoji = emojis[i];
          return (
            <Reaction
              key={key}
              index={i}
              icon={emoji.icon}
              count={counts[emoji.type]}
              colour={emoji.colour}
              className={activeReaction && activeReaction === emoji.type ? 'activeReaction' : null}
              clickHandler={clickReaction}
            />
          )
        })
      }
      <ReactionModal emojis={emojis} open={modalOpen} toggle={toggleModal} reactions={reactions} />
      <button onClick={toggleModal}><span>...</span></button>
    </StyledReactions>
  )
};


export default connect(null, {
  reactToNote
})(Reactions);