import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StickyBoard from '../../components/Board/StickyBoard';
import BoardModal from '../../components/Modals/BoardModal';
import Loader from '../../components/Loader/Loader';
import {
  getNotes,
  updateNote
} from '../../store/actions/notesActions';
import { setLabel } from '../../store/actions/labelActions';
import { logOutUser } from '../../store/actions/authActions';

const Board = ({
    auth,
    match,
    getNotes,
    updateNote,
    logOutUser,
    activeLabel,
    setLabel,
    label,
    history,
    boards: {
      boards
    },
    notes: {
      notes,
      isLoading,
      error
    }
  }) => {
  const [selectModal, spawnSelect] = useState(false);

  useEffect(() => {
    if(label.length === 0) {
      spawnSelect(true);
    }
  }, [label])

  const getData = async () => {
    console.log(history.location);
    console.log(match.params.id);
    if(!match.params.id) {
      spawnSelect(true);
      return;
    } else {
      const boardExists = await boards.filter(board => board.id === match.params.id);
      console.log(boardExists);
      if(boardExists.length === 1) {
        setLabel(boardExists[0].title)
        spawnSelect(false);
        console.log(39);
        getNotes(match.params.id);
      } else {
        console.log(42);
        spawnSelect(true);
      }
    }
  }

  const triggerPageCheck = async (id) => {
    const board = await boards.filter(board => board.id === id);
    console.log(id);
    setLabel(board[0].title);
    spawnSelect(false);
    getNotes(id);
  }

  useEffect(() => {
    if(boards.length > 0) {
      getData()
    }
  }, [boards]);

  const logOut = () => {
    logOutUser(history);
  }

  return (
    <div className="home-page">
      {!auth.isAuthenticated ? (
          <BoardModal
            isOpen={!auth.isAuthenticated}
            boards={[]}
            user={null}
            logOut={null}
            type="login"
          />
      ) : (
        <>
          <BoardModal
            isOpen={selectModal}
            boards={boards}
            user={auth.me}
            logOut={logOut}
            type="select"
            history={history}
            checkPage={triggerPageCheck}
          />
          {error && <div style={{display: 'none'}} className="error-center">{error === 'Not the message owner or admin.' ? '' : error}</div>}
          { selectModal ? (
            null
          ) :
           isLoading ? (
            <Loader />
          ) : (
            <>
              <StickyBoard
                boardId={match.params.id}
                notes={notes}
                updateNote={updateNote}
                user={auth.me}
                getNotes={getNotes}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  notes: state.notes,
  boards: state.boards,
  label: state.label.label
});

export default withRouter(compose(connect(mapStateToProps, {
  getNotes,
  updateNote,
  logOutUser,
  setLabel
}))(Board));
