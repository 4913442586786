import React from 'react';
import { ReactComponent as LoaderAnim } from './Loader.svg';
import styled from 'styled-components';

const StyledLoader = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.1);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 250px;
    transform: translate(-50%, -50%);
  }
`;

const Loader = (props) => {
  return (
    <StyledLoader className="loader-container loader" {...props}>
      <LoaderAnim />
    </StyledLoader>
  );
};

export default Loader;
