import styled from 'styled-components';
import bg from './bg.jpg';

const StyledLayout = styled.div`
  background-image: url(${bg});
  background-attachment: fixed;
  height: 100vh;

  .container {
    width: 80%;
    margin: 0 auto;
    /* border: 1px solid green; */
    min-height: calc(100% - 131px);
  }
`

export default StyledLayout;