import {
  GET_NOTES_LOADING,
  GET_NOTES_SUCCESS,
  GET_NOTES_FAIL
} from '../types';

const initialState = {
  notes: [],
  isLoading: false,
  error: null,
};

// const setupNotes = (notes) => {
//     const obj = {};
//     notes.map(note => {
//       obj[note.id] = note
//     });
//   return obj;
// }

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_NOTES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_NOTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notes: payload.notes,
      };
    case GET_NOTES_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    default:
      return state;
  }
}
