import styled from 'styled-components';

const StyledControls = styled.div`
  content: '';
  display: block;
  position: absolute;
  top: -35px;
  width: 100%;
  right: -8px;
  display: flex;
  justify-content: flex-end;

  .control {
    position: relative;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    background-color: ${props => props.atts.colour};
    margin-right: 4px;
    cursor: pointer;

    svg {
      position: absolute;
      width: 60%;
      height: 60%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.lg-icon svg {
      width: 90%;
      height: 90%;
    }
  }
`;

export default StyledControls;