import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getBoards } from '../../store/actions/boardsActions';
import { setLabel } from '../../store/actions/labelActions';

const BoardNav = ({
    getBoards,
    setLabel,
    boards: {
      boards,
      isLoading,
      error
    },
    label
  }) => {
  useEffect(() => {
    getBoards();
  }, []);

  const showModal = () => {
    setLabel('');
  }

  return (
    <>
      {error && error}
        {isLoading ? null : (
          <span onClick={showModal} style={{fontWeight: 800, color: '#FBBF09', cursor: 'pointer'}}>
            {label.label} <i style={{marginRight: 5, marginLeft: 2, fontSize: 28, transform: 'translateY(3px)'}} className="fa fa-angle-down" aria-hidden="true"></i>
          </span>
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  boards: state.boards,
});

export default connect(mapStateToProps, {
  getBoards,
  setLabel
})(BoardNav);
