import styled from 'styled-components';
import Modal from 'react-modal';

const StyledModal = styled(Modal)`
  position: fixed;
  width: 550px;
  background-color: #415263;
  color: #fff;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.4);

  .logoutlink {
    cursor: pointer;
  }

  .emphasis {
    font-weight: 800;
    color: #FBBF09;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    h1 {
      margin: 0;
      font-size: 26px;
      font-weight: 400;
    }

    svg {
      height: 40px;
    }
  }

  select {
    width: 100%;
    padding: 4px 0;
  }

  button {
    margin-top: 50px;
    position: relative;
    background-color: #415263;
    border: 1px solid #fff;
    color: #fff;
    padding: 4px 50px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }

  .google.btn {
    color: #415263;
  }
`;

export default StyledModal;