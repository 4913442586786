import React, { useState, useEffect } from 'react';
import PostIt from './PostIt';
import StyledStickyBoard from './StyledStickyBoard';
import Tools from './Tools';
import blankNote from './utils/blankNote';

const StickyBoard = ({
    notes,
    updateNote,
    user,
    boardId,
    getNotes
  }) => {
  const [activeNote, setActiveNote] = useState('');
  const [hoveredNote, setHoveredNote] = useState('');
  const [currentNotes, setCurrentNotes] = useState(notes);

  useEffect(() => {
    if(notes !== currentNotes){
      setCurrentNotes(notes);
    }
  }, [notes]);

  const updateDb = (note, shouldDelete = false) => {
    updateNote(note, shouldDelete);
  }

  const deleteNote = async (key) => {
    if(window.confirm('Are you sure you want to delete this note?')){
      const updatedNotes = await currentNotes.filter(note => {
        return note._id === key ? false : true;
      });
      const update = await currentNotes.filter(note => note._id === key)[0];
      updateDb(update, true);
      setCurrentNotes(updatedNotes);
    }
  }

  const update = () => {
    getNotes(boardId);
  }

  const newNote = (colour, position) => {
    const note = blankNote(user.id, boardId, colour, position);
    updateNote(note);
  }

  const handleStop = async (data, key) => {
    const updatedNotes = await currentNotes.map(note => {
      if(note._id === key) {
        note.position = {
          x: data.x,
          y: data.y
        }
      }
      return note;
    });
    setCurrentNotes(updatedNotes);
    const update = await updatedNotes.filter(note => note._id === key)[0];
    console.log(update);
    updateDb(update);
  }

  const handleResizeStop = async (e, key, direction, ref, delta, position) => {
    const updatedNotes = await currentNotes.map(note => {
      if (note._id === key) {
        note.size = {
          width: ref.style.width,
          height: ref.style.height,
        }
        note.position = {
          ...position
        }
      }
      return note;
    });
    setCurrentNotes(updatedNotes);
    const update = await updatedNotes.filter(note => note._id === key)[0];
    updateDb(update);
  }

  const handleChangeValues = async (data, key, type) => {
    const updatedNotes = await currentNotes.map(note => {
      if (note._id === key) {
        note[type] = data.target.value
      }
      return note;
    });
    setCurrentNotes(updatedNotes);
    const update = await updatedNotes.filter(note => note._id === key)[0];
    updateDb(update);
  }

  const handleChangeContent = async (data, key, type) => {
    console.log(data);
    const updatedNotes = await currentNotes.map(note => {
      if (note._id === key) {
        note.content[type] = data
      }
      return note;
    });
    setCurrentNotes(updatedNotes);
    const update = await updatedNotes.filter(note => note._id === key)[0];
    updateDb(update);
  }

  const clickCanvas = e => {
    if(e.target.id === 'board') {
      setActiveNote('');
    }
  }

  return (
    <StyledStickyBoard
      onClick={clickCanvas}
      id="board"
    >
      {
        currentNotes.map(note => (
          <PostIt
            note={note}
            key={note._id}
            myKey={note._id}
            handleStop={handleStop}
            handleChange={handleChangeValues}
            handleChangeContent={handleChangeContent}
            activeNote={activeNote}
            setActiveNote={setActiveNote}
            handleResizeStop={handleResizeStop}
            hoveredNote={hoveredNote}
            setHovered={setHoveredNote}
            user={user}
            newNote={newNote}
            handleDelete={deleteNote}
          />
        ))
      }
      <Tools newNote={newNote} refresh={update} />
    </StyledStickyBoard>
  );
};

export default StickyBoard;