import { v4 as uuidv4 } from 'uuid';

export default (userId, boardId, colour, position) => {
  return {
    _id: uuidv4(),
    content: {
      title: 'Title',
      content: ''
    },
    position: {
      x: position ? position.x : 200,
      y: position ? position.y : 220
    },
    size: {
      width: 300,
      height: 200
    },
    // colour: `${colour ? colour : '#fffc7f99'}`,
    colour: `${colour && colour.length > 0 ? colour : '#FBBF0999'}`,
    meta: {
      user: userId,
    },
    author: userId,
    board: boardId
  }
};

// const [notes, updateNotes] = useState({
//   afsdfds: {
//     content: {
//       title: 'Title',
//       content: ''
//     },
//     position: {
//       x: 200,
//       y: 220
//     },
//     size: {
//       width: 250,
//       height: 200
//     },
//     colour: '#fffc7f',
//     meta: {
//       user: '',
//     }
//   }
// });