import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Navbar from '../components/Navbar/Navbar';
import StyledLayout from './StyledLayout';

const Layout = ({ children }) => {
  return (
    <StyledLayout>
      <Navbar />
      <div className="container">{children}</div>
    </StyledLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
