import styled from 'styled-components';

const StyledPostIt = styled.div`
  /* padding: 2rem; */
  position: relative;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.4);
  /* box-shadow: 0px 1px 21px 0px rgba(0,0,0,0.62); */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${props => props.myColour} none repeat scroll 0;

  .editBox {
    content: '';
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    top: -35px;
    right: -35px;
    background-color: ${props => props.myColour};
    border-radius: 100%;
  }

  .title {
    /* background-color: red; */
    background: ${props => props.myColour} none repeat scroll 0;
    opacity: 1;
    height: 36px;
    box-sizing: border-box;
    padding: 10px;
    font-weight: 800;
  }

  .body {
    /* background-color: blue; */
    flex: 1;
    box-sizing: border-box;
    padding: 10px;
    overflow: scroll;
    background-color: transparent !important;
    color: #000 !important;
    line-height: 1.3 !important;
    font-size: 14px;
    /* opacity: 0.6; */

    div,
    span {
      background-color: transparent !important;
      line-height: 1.3 !important;
      font-size: 14px;
      color: #000 !important;
    }

    h1 {
      margin: 0;
      font-size: 1.5rem;
    }

    a {
      color: blue;
      text-decoration: underline;
      font-weight: 800;
    }
  }

  .footer {
    height: 30px;
    font-size: 10px;
    opacity: 0.6;
    box-sizing: border-box;
    padding: 10px;
    text-align: right;
    /* background-color: green; */
  }
`

export default StyledPostIt;