import {
  GET_BOARDS_LOADING,
  GET_BOARDS_SUCCESS,
  GET_BOARDS_FAIL,
  EDIT_BOARD_LOADING,
  EDIT_BOARD_SUCCESS,
  EDIT_BOARD_FAIL,
  CREATE_BOARD_LOADING,
  CREATE_BOARD_SUCCESS,
  CREATE_BOARD_FAIL,
  DELETE_BOARD_LOADING,
  DELETE_BOARD_SUCCESS,
  DELETE_BOARD_FAIL,
} from '../types';

const initialState = {
  boards: [],
  isLoading: false,
  error: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_BOARDS_LOADING,
         EDIT_BOARD_LOADING,
         CREATE_BOARD_LOADING,
         DELETE_BOARD_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_BOARD_SUCCESS:
      const updated = state.boards.map(board => {
        if(board.id === payload.board.id) {
          return payload.board;
        } else {
          return board;
        }
      });
      return {
        ...state,
        isLoading: false,
        boards: updated
      };
    case DELETE_BOARD_SUCCESS:
      const updatedBoards = state.boards.filter(board => {
        if(board.id === payload.board.id) {
          return false
        }
        return true;
      });
      return {
        ...state,
        isLoading: false,
        boards: updatedBoards
      }
    case CREATE_BOARD_SUCCESS:
      const boards = state.boards;
      boards.push(payload.board);
      return {
        ...state,
        isLoading: false,
        boards
      }
    case GET_BOARDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        boards: payload.boards,
      };
    case GET_BOARDS_FAIL,
         EDIT_BOARD_FAIL,
         CREATE_BOARD_FAIL,
         DELETE_BOARD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    default:
      return state;
  }
}
