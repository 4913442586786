import React from 'react';
import StyledControls from './StyledControls';
import Reactions from './Reactions';
import { ReactComponent as AddIcon } from './add.svg';
import { ReactComponent as EditIcon } from './edit.svg';
import { ReactComponent as DeleteIcon } from './delete.svg';

const HoverControls = ({
    note,
    newNote,
    enableEdit,
    deleteNote
  }) => {
  const handleNewNote = () => {
    newNote(
      note.colour,
      { x: note.position.x + 150,
        y: note.position.y + 150
      }
      );
  }

  return (
    <StyledControls
      atts={note}
    >
      <div onClick={handleNewNote} className="control control__lg-icon">
        <AddIcon />
      </div>
      <div onClick={enableEdit} className="control">
        <EditIcon />
      </div>
      <div onClick={deleteNote} className="control">
        <DeleteIcon />
      </div>
    </StyledControls>
  )
};

export default HoverControls;