export const colours = [
  '#FBBF0999',
  '#E0BBE499',
  '#957DAD99',
  '#D291BC99',
  '#FEC8D899',
  '#FFDFD399',
  '#fffc7f99',
  '#cdf36599'
];

export const randomColour = () => {
  const index = Math.floor(Math.random() * colours.length);
  return colours[index];
}